import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/heap.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Heap?</SubTitle>
      <p>
        Heap is a product analytics tool to help you understand user behavior. Connecting it with
        LiveSession comes with a range of advantages:
      </p>
      <ul>
        <li>Access session recordings directly from Heap event properties</li>
        <li>Learn even more about your users </li>
        <li>Get the most from both Heap and LiveSession</li>
      </ul>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        With this integration, every new event in Heap comes with an extra label called{' '}
        <i>sessionURL</i>. In the event properties, you’ll find a direct link to the session
        recording.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script>
__ls("getSessionURL", function (url, isNewSession) {
  heap.track("SessionURL", { sessionURL: url });
});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        For this integration to work, you need to have both Heap and LiveSession installed on your
        website. If you haven’t installed our tracking code yet, check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Heap integration',
  canonical: '/help/heap-integration/',
  metaDescription: 'Go to session directly from Heap event properties.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
